import { v4 as uuidv4 } from 'uuid';
import { uploadFileToBlob } from '../../services/azureStorageBlob';

let mediaRecorder;
let recordedBlobs;
let videoOptions = { mimeType: 'video/mp4' };

const handleDataAvailable = (event) => {
  if (event.data && event.data.size > 0) {
    recordedBlobs.push(event.data);
  }
};

export const stopRecording = (callback) => {
  if (mediaRecorder && mediaRecorder.state === 'recording') {
    mediaRecorder.onstop = callback;
    mediaRecorder.stop();
  }
};

export const startRecording = (stream) => {
  recordedBlobs = [];
  // Different browsers use different codecs for recording media.
  // The code below will pick the exact codec for the browser or will alert an error.
  if (!MediaRecorder.isTypeSupported(videoOptions.mimeType)) {
    videoOptions = { mimeType: 'video/webm;codecs=vp9,opus' };

    if (!MediaRecorder.isTypeSupported(videoOptions.mimeType)) {
      videoOptions = { mimeType: 'video/webm;codecs=vp8,opus' };

      if (!MediaRecorder.isTypeSupported(videoOptions.mimeType)) {
        videoOptions = { mimeType: 'video/webm' };

        if (!MediaRecorder.isTypeSupported(videoOptions.mimeType)) {
          alert(
            "This browser doesn't support video recording. Please use another one."
          );
          return;
        }
      }
    }
  }

  try {
    mediaRecorder = new MediaRecorder(stream, videoOptions);
  } catch (e) {
    console.warn('Exception while creating MediaRecorder:', e);
    return;
  }

  mediaRecorder.ondataavailable = handleDataAvailable;
  mediaRecorder.start();
};

export const pauseRecording = () => {
  if (mediaRecorder && mediaRecorder.state === 'recording') {
    mediaRecorder.pause();
  }
};

export const resumeRecording = () => {
  if (mediaRecorder && mediaRecorder.state === 'paused') {
    mediaRecorder.resume();
  }
};

export const postVideo = async () => {
  if (!recordedBlobs || !recordedBlobs.length) {
    return;
  }

  const personId = localStorage.getItem('personId');

  const blob = new Blob(recordedBlobs, { type: videoOptions.mimeType });
  const fileExtension = blob.type === 'video/mp4' ? '.mp4' : '.webm';
  const fileName = `${personId}-${uuidv4()}${fileExtension}`;

  blob.name = fileName;

  const response = new Response(await uploadFileToBlob(blob));
  recordedBlobs = [];

  if (response.status === 200) {
    return fileName;
  }
};

export const repeatRecording = () => {
  recordedBlobs = [];
};

export const getRecordingState = () => {
  return mediaRecorder ? mediaRecorder.state : 'undefined';
};
