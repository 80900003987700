import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppFooter from '../../components/AppFooter';
import InstructionBg from '../../assets/Images/instruction-bg.png';
import InstructionBgMobile from '../../assets/Images/instruction-bg-mobile.png';
import ArrowUp from '../../assets/Images/arrow-up.svg';
import LogoWhite from '../../assets/Images/logo-white.svg';
import LogoRed from '../../assets/Images/logo-vertical.png';
import LogoGrey from '../../assets/Images/lira-logo-grey-horizontal-half.png';
import styles from './Prepare.module.css';

const Prepare = () => {
  const history = useHistory();

  const onAgreeTerms = () => {
    history.push('/record');
  };

  return (
    <div className={styles.container}>
      <img
        className={[styles.logoRedMobile, styles.showMobile].join(' ')}
        src={LogoRed}
        alt="Logo"
      />
      <div className={styles.pageLayout}>
        <div className={styles.cameraArea}>
          <div className={styles.instructionContainer}>
            <img
              className={[styles.instructionBg, styles.hideMobile].join(' ')}
              src={InstructionBg}
              alt="Face"
            />
            <img
              className={[styles.instructionBgMobile, styles.showMobile].join(
                ' '
              )}
              src={InstructionBgMobile}
              alt="Face"
            />
            <div className={styles.instructionContent}>
              <img className={styles.arrowUp} src={ArrowUp} alt="ArrowUp" />
              <p className={styles.instruction}>
                Make sure you have your face
                <br />
                aligned inside this shape
              </p>
            </div>
            <img
              className={[styles.logoWhite, styles.hideMobile].join(' ')}
              src={LogoWhite}
              alt="Logo"
            />
          </div>
        </div>
        <div className={styles.termsArea}>
          <img
            className={[styles.logoRed, styles.hideMobile].join(' ')}
            src={LogoRed}
            alt="Logo"
          />
          <div className={styles.agreeInstructionContainer}>
            <p className={styles.agreeInstruction}>
              Please check the box below to{' '}
              <span className={styles.fontRed}>agree</span> to the data
              collection terms.
            </p>
            <img
              className={[styles.logoGrey, styles.hideMobile].join(' ')}
              src={LogoGrey}
              alt="Logo"
            />
          </div>
          <div className={styles.agreeTerms}>
            <input
              className={styles.agreeCheck}
              type="checkbox"
              onChange={onAgreeTerms}
            />
            <span className={styles.agreeDesc}>
              <span className={styles.fontYellow}>I agree</span> to the LiRA
              data collection <Link to="/terms">terms</Link>
            </span>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default Prepare;
