import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppFooter from '../../components/AppFooter';
import LiraLogoGrey1 from '../../assets/Images/lira-logo-grey-1.png';
import LiraLogoGrey2 from '../../assets/Images/lira-logo-grey-2.png';
import CongratulationsImg from '../../assets/Images/congratulations.png';
import EmailIcon from '../../assets/Images/email.svg';
import FacebookIcon from '../../assets/Images/facebook.png';
import LinkedinIcon from '../../assets/Images/linkedin.png';
import TwitterIcon from '../../assets/Images/twitter.png';
import LogoGreyFull from '../../assets/Images/lira-logo-grey-horizontal.png';
import { getOrCreateUser } from '../../services/LiraLipTrain';
import styles from './ThankYou.module.css';

const ThankYou = () => {
  const [email, setEmail] = useState('');

  const location = useLocation();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSignUp = async () => {
    await getOrCreateUser(email);
    toast.success('Signed Up successfully!');
  };

  return (
    <div className={styles.container}>
      <img
        className={[styles.bgLogoUp, styles.hideMobile].join(' ')}
        src={LiraLogoGrey1}
        alt="Logo Grey"
      />
      <img
        className={[styles.bgLogoDown, styles.hideMobile].join(' ')}
        src={LiraLogoGrey2}
        alt="Logo Grey"
      />
      <div className={styles.pageLayout}>
        <div className={styles.halfLayout}>
          <div className={styles.blockBig}>
            <img
              className={styles.congratulationImg}
              src={CongratulationsImg}
              alt="Congratulations"
            />
            <span className={styles.fontBigBold}>Congratulations!</span>
            <p className={styles.fontSmall}>
              Thank you for being the voice for the voiceless
            </p>
          </div>
          <div className={styles.blockSmall}>
            <span className={styles.fontMediumBold}>
              Invite Friends and Family
            </span>
            <span className={styles.fontSmall}>
              Invite friends and family to be the voice for the voicelesss
            </span>
            <div className={styles.inviteIcons}>
              <a
                href="mailto:?subject= LiRA: Be a Voice for the Voiceless. &amp;body= Hi Folks, Participate in LipTrain and read sentences to help folks with voicelessness. http://www.liraglobal.com/liptrain"
                target="_blank"
                rel="noreferrer">
                <img
                  className={styles.inviteIcon}
                  src={EmailIcon}
                  alt="Email"
                />
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=www.liraglobal.com%2Fliptrain%2F&quote=Read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20%40LiRAGlobal"
                target="_blank"
                rel="noreferrer">
                <img
                  className={styles.inviteIcon}
                  src={FacebookIcon}
                  alt="Facebook"
                />
              </a>
              <a
                href="https://www.linkedin.com/sharing/share-offsite/?url= www.liraglobal.com/liptrain/%26utm_content%3DRead%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20@LiRAGlobal%20"
                target="_blank"
                rel="noreferrer">
                <img
                  className={styles.inviteIcon}
                  src={LinkedinIcon}
                  alt="Linkedin"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.halfLayout}>
          <div className={styles.blockBig}>
            <div className={styles.wordsCompletedCard}>
              <div className={styles.wordsCompleted}>
                <span className={styles.completedText}>
                  Sentences<span className={styles.hideMobile}> Completed</span>
                </span>
                <span className={styles.completedCount}>
                  {location.state?.sentences || 0}
                </span>
              </div>
              <img className={styles.logoGrey} src={LogoGreyFull} alt="Logo" />
            </div>
            <p className={styles.fontSmall}>
              <span className={styles.hideMobile}>Your voice matters.</span>
              Share the excitement!
            </p>
            <div className={styles.social}>
              <a
                className={styles.socialLink}
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=www.liraglobal.com%2Fliptrain%2F&quote=I%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20%40LiRAGlobal"
                rel="noreferrer">
                <img
                  className={styles.socialIcon}
                  src={FacebookIcon}
                  alt="Facebook"
                />
              </a>
              <a
                className={styles.socialLink}
                target="_blank"
                href="https://www.linkedin.com/sharing/share-offsite/?url= www.liraglobal.com/liptrain/%26utm_content%3DI%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20@LiRAGlobal%20"
                rel="noreferrer">
                <img
                  className={styles.socialIcon}
                  src={LinkedinIcon}
                  alt="Linkedin"
                />
              </a>
              <a
                className={styles.socialLink}
                target="_blank"
                href="https://twitter.com/intent/tweet?text=I%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20@LiRAGlobal%20&url=www.liraglobal.com/liptrain/"
                rel="noreferrer">
                <img
                  className={styles.socialIcon}
                  src={TwitterIcon}
                  alt="Twitter"
                />
              </a>
            </div>
          </div>
          <div className={styles.blockSmall}>
            <span
              className={[styles.fontMediumBold, styles.updatesBlock].join(
                ' '
              )}>
              LiRA Updates
            </span>
            <p className={styles.fontSmall}>
              Please share your email for LiRA updates
            </p>
            <div className={styles.emailSignUp}>
              <input
                className={styles.emailInput}
                placeholder="Email Address"
                value={email}
                onChange={onEmailChange}
              />
              <button className={styles.signUpBtn} onClick={onSignUp}>
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default ThankYou;
