import React from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-slick';
import { toast } from 'react-toastify';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { videoLink } from '../../config/app';
import PeopleImg from '../../assets/Images/people.png';
import PeopleImgMobile from '../../assets/Images/people-mobile.png';
import LiraLogoGrey1 from '../../assets/Images/lira-logo-grey-1.png';
import LiraLogoGrey2 from '../../assets/Images/lira-logo-grey-2.png';
import ArrowDown from '../../assets/Images/arrow-down.png';
import Facebook from '../../assets/Images/facebook.png';
import Linkedin from '../../assets/Images/linkedin.png';
import Twitter from '../../assets/Images/twitter.png';
import Speeches from '../../assets/Images/speeches.png';
import SpeechesMobile from '../../assets/Images/speeches-mobile.png';
import { getOrCreateUser } from '../../services/LiraLipTrain';
import { formatNumber } from '../../util/format';
import styles from './Home.module.css';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} stories-carousel-arrow`}
      style={style}
      onClick={onClick}>
      <svg
        width="16.5"
        height="35.2"
        viewBox="0 0 21 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.5 37.2407L2 19.6203L18.5 2"
          stroke="#DB761B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} stories-carousel-arrow`}
      style={style}
      onClick={onClick}>
      <svg
        width="16.5"
        height="35.2"
        viewBox="0 0 21 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.25 37.2407L18.75 19.6203L2.25 2"
          stroke="#DB761B"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const TESTIMONIALS = [
  {
    id: 1,
    title: 'Speech Language Pathologist',
    content: `"I've been a bedside clinician working with patients with communication disorders for 22 years, and this is the first product I've seen that I truly believe could make a big difference for my patients."`
  },
  {
    id: 2,
    title: 'Critical Care Physician',
    content: `"Giving patients their ‘voice’ back is an important recognition of patient autonomy.`
  }
];

const TESTIMONIALS_CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  dotsClass: 'testimonials-carousel-dots slick-dots',
  autoplay: true,
  autoplaySpeed: 5000,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const STORIES_CAROUSEL_SETTINGS = {
  dots: true,
  infinite: false,
  arrows: true,
  dotsClass: 'stories-carousel-dots slick-dots',
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const STORIES = [
  {
    name: 'James',
    url: 'https://www.youtube.com/embed/rubNHVBw0IQ',
    title: 'James | Be a Voice for the Voiceless | Voice Collective'
  },
  {
    name: 'Marian',
    url: 'https://www.youtube.com/embed/yzjmnZ4Ko9A',
    titel: 'Marian - SLP | Be a Voice for the Voiceless | Voice Collective'
  },
  {
    name: 'Rob',
    url: 'https://www.youtube.com/embed/5JgRfL1sC1k',
    title: 'Rob | Be a Voice for the Voiceless | Voice Collective'
  }
];

const Home = () => {
  const history = useHistory();

  const continueButtonHandler = async () => {
    try {
      await getOrCreateUser('');
      history.push('/prepare');
    } catch (err) {
      toast.error('Failed to Create a Person');
    }
  };

  return (
    <>
      <Header />
      <section className={styles.startHelpingSection}>
        <div className={styles.startHelpingContentContainer}>
          <div className={styles.startHelpingContentWithImg}>
            <div className={styles.startHelpingContent}>
              <h1 className={styles.sectionTitle}>
                Be a voice for
                <br />
                the voiceless
              </h1>
              <p className={styles.startHelpingSectionDesc}>
                Make a difference today and help
                <br />
                voiceless individuals regain communication.
              </p>
              <button
                className={styles.btnStartHelping}
                onClick={continueButtonHandler}>
                Start Helping!
              </button>
            </div>
            <img
              className={styles.peopleImgMobile}
              src={PeopleImgMobile}
              alt="People"
            />
          </div>
          <p className={styles.startHelpingSectionDescMobile}>
            Make a difference today and help voiceless
            <br />
            individuals regain communication.
          </p>
          <div className={styles.testimonials}>
            <Carousel {...TESTIMONIALS_CAROUSEL_SETTINGS}>
              {TESTIMONIALS.map((testimonial) => (
                <div className={styles.testimonialCard} key={testimonial.id}>
                  <h5 className={styles.testimonialTitle}>
                    {testimonial.title}
                  </h5>
                  <p className={styles.testimonialDesc}>
                    {testimonial.content}
                  </p>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className={styles.bannerImgContainer}>
          <img className={styles.peopleImg} src={PeopleImg} alt="People" />
          <a href="#videoSection">
            <img className={styles.arrowDown} src={ArrowDown} alt="ArrowDown" />
          </a>
        </div>
      </section>
      <section id="videoSection" className={styles.videoSection}>
        <img className={styles.curveLineBg} src={LiraLogoGrey1} alt="CurveBg" />
        <div className={styles.sectionContainer}>
          <h3
            className={[
              styles.sectionSubTitle,
              styles.videoSectionSubTitle
            ].join(' ')}>
            Every year <span className={styles.fontRed}>one million</span>{' '}
            individuals become
            <br />
            voiceless, suddenly and unexpectedly.
          </h3>
          <span className={styles.videoSectionSubDesc}>
            Source: American Journal of Respiratory Critical Care Medicine
          </span>
          <div className={styles.video}>
            <div className={styles.videoWrapper}>
              <iframe
                width="560"
                height="315"
                src={videoLink}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="LiRA Aphonic Individual Interview 2021"
              />
            </div>
          </div>
          <p className={styles.sectionDesc}>
            Give back the ability to{' '}
            <span className={styles.fontOrange}>communicate</span>
          </p>
        </div>
      </section>
      <section className={styles.voiceCollectiveSection}>
        <img
          className={styles.greyLogoBg}
          src={LiraLogoGrey2}
          alt="GreyLogoBg"
        />
        <div
          className={[
            styles.sectionContainer,
            styles.voiceCollectiveSectionContainer
          ].join(' ')}>
          <h3 className={[styles.sectionSubTitle, styles.fontWhite].join(' ')}>
            Join the Voice Collective!
          </h3>
          <div className={styles.metrics}>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>Participants</span>
              <span className={styles.metricValue}>{formatNumber(1232)}</span>
            </div>
            <div className={styles.metricCard}>
              <span className={styles.metricLabel}>Sentences</span>
              <span className={styles.metricValue}>{formatNumber(415)}</span>
            </div>
            <div className={[styles.metricCard, styles.hideMobile].join(' ')}>
              <span className={styles.metricLabel}>Words</span>
              <span className={styles.metricValue}>{formatNumber(2227)}</span>
            </div>
          </div>
          <div className={styles.shareCollective}>
            <div className={styles.socialContainer}>
              <span className={styles.socialTitle}>
                Share the Collective Impact!
              </span>
              <div className={styles.social}>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=www.liraglobal.com%2Fliptrain%2F&quote=I%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20%40LiRAGlobal"
                  rel="noreferrer">
                  <img
                    className={styles.socialIcon}
                    src={Facebook}
                    alt="Facebook"
                  />
                </a>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  href="https://www.linkedin.com/sharing/share-offsite/?url= www.liraglobal.com/liptrain/%26utm_content%3DI%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20@LiRAGlobal%20"
                  rel="noreferrer">
                  <img
                    className={styles.socialIcon}
                    src={Linkedin}
                    alt="Linkedin"
                  />
                </a>
                <a
                  className={styles.socialLink}
                  target="_blank"
                  href="https://twitter.com/intent/tweet?text=I%20read%20sentences%20to%20support%20folks%20in%20need.%20Participate%20in%20LipTrain!%20Be%20a%20voice%20for%20the%20voiceless.%20%20@LiRAGlobal%20&url=www.liraglobal.com/liptrain/"
                  rel="noreferrer">
                  <img
                    className={styles.socialIcon}
                    src={Twitter}
                    alt="Twitter"
                  />
                </a>
              </div>
            </div>
            <div className={[styles.impactValues, styles.hideMobile].join(' ')}>
              <div className={styles.impactChart}>
                <div className={styles.pieChart}>20%</div>
              </div>
              <span className={styles.pledgeWords}>
                Pledge{' '}
                <span className={styles.fontOrange}>{formatNumber(10000)}</span>{' '}
                Words
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.storiesSection}>
        <div className={styles.sectionContainer}>
          <h3 className={styles.sectionSubTitle}>Stories of the Voiceless</h3>
          <div className={styles.storiesCarousel}>
            <Carousel {...STORIES_CAROUSEL_SETTINGS}>
              {STORIES.map((story) => (
                <div className={styles.storyCard} key={story.url}>
                  <div className={styles.videoWrapper}>
                    <iframe
                      width="1280"
                      height="720"
                      className={styles.storyVideo}
                      src={story.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={story.title}
                    />
                  </div>
                  <div className={styles.storyNameContainer}>
                    <span className={styles.storyName}>{story.name}</span>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <p
            className={[styles.sectionDesc, styles.storySectionDescMobile].join(
              ' '
            )}>
            Be a bridge with <span className={styles.fontOrange}>words.</span>
            <span className={styles.hideMobile}>
              {' '}
              Join the Voice Collective.
            </span>
          </p>
        </div>
      </section>
      <section className={styles.helpTheVoicelessSection}>
        <div className={styles.sectionContainer}>
          <h3
            className={[
              styles.sectionSubTitle,
              styles.helpTheVoicelessSectionSubTitle
            ].join(' ')}>
            Losing your speech can be devastating
          </h3>
          <p className={styles.sectionDesc}>
            In a <span className={styles.fontRed}>few minutes</span>, help
            someone find their voice again.
            <span className={styles.hideMobile}>
              {' '}
              We need you to record yourself saying common sentences voiceless
              individuals need to communicate.
            </span>
          </p>
        </div>
        <img
          className={[styles.speechesImg, styles.hideMobile].join(' ')}
          src={Speeches}
          alt="Speeches"
        />
        <img
          className={[styles.speechesImg, styles.showMobile].join(' ')}
          src={SpeechesMobile}
          alt="Speeches"
        />
        <p className={[styles.sectionDesc, styles.showMobile].join(' ')}>
          We need you to record yourself saying common sentences voiceless
          individuals need to communicate.
        </p>
        <button
          className={styles.btnStartHelping}
          onClick={continueButtonHandler}>
          Start Helping!
        </button>
      </section>
      <Footer />
    </>
  );
};

export default Home;
