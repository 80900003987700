import React from 'react';
import logo from '../../assets/Images/logo.png';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.container}>
      <a href="https://liraglobal.com" target="_blank" rel="noreferrer">
        <img src={logo} className={styles.logo} alt="Logo" />
      </a>
      <div className={styles.menu}>
        <a
          className={styles.menuItem}
          href="https://liraglobal.com"
          target="_blank"
          rel="noreferrer">
          Home
        </a>
        <a
          className={styles.menuItem}
          href="https://liraglobal.com/ourstory/"
          target="_blank"
          rel="noreferrer">
          About Us
        </a>
        <a
          className={styles.menuItem}
          href="https://liraglobal.com/contact-us/">
          Contact
        </a>
      </div>
    </div>
  );
};

export default Header;
