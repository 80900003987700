import { BlobServiceClient } from '@azure/storage-blob';

const sasToken = process.env.REACT_APP_AZURE_BLOB_STORAGE_SAS_TOKEN;
const containerName = process.env.REACT_APP_AZURE_BLOB_STORAGE_CONTAINER_NAME;
const storageAccountName =
  process.env.REACT_APP_AZURE_BLOB_STORAGE_ACCOUNT_NAME;

export const uploadFileToBlob = async (file) => {
  if (!file) return [];

  // get BlobService
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  return createBlobInContainer(containerClient, file);
};

const createBlobInContainer = async (containerClient, file) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  return await blobClient.uploadBrowserData(file, options);
};
