import React from 'react';
import { Link } from 'react-router-dom';
import Facebook from '../../assets/Images/facebook-sm.png';
import Linkedin from '../../assets/Images/linkedin-sm.png';
import Twitter from '../../assets/Images/twitter-sm.png';
import Instagram from '../../assets/Images/instagram-sm.png';
import Logo from '../../assets/Images/logo-vertical.png';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer>
      <div className={styles.footerMain}>
        <a
          className={styles.showMobile}
          href="https://liraglobal.com"
          target="_blank"
          rel="noreferrer">
          <img className={styles.logo} src={Logo} alt="Logo" />
        </a>
        <div className={styles.descContainer}>
          <span className={[styles.desc, styles.hideMobile].join(' ')}>
            Together, let&apos;s make a difference. Share the word.
          </span>
          <div className={styles.socialIcons}>
            <a
              className={styles.socialLink}
              href="https://www.facebook.com/liraglobal"
              target="_blank"
              rel="noreferrer">
              <img
                className={styles.socialIcon}
                src={Facebook}
                alt="Facebook"
              />
            </a>
            <a
              className={styles.socialLink}
              href="https://www.linkedin.com/company/lipreadingassistant"
              target="_blank"
              rel="noreferrer">
              <img
                className={styles.socialIcon}
                src={Linkedin}
                alt="Linkedin"
              />
            </a>
            <a
              className={styles.socialLink}
              href="https://twitter.com/LiRAGlobal"
              target="_blank"
              rel="noreferrer">
              <img className={styles.socialIcon} src={Twitter} alt="Twitter" />
            </a>
            <a
              className={styles.socialLink}
              href="https://www.instagram.com/liraglobal"
              target="_blank"
              rel="noreferrer">
              <img
                className={styles.socialIcon}
                src={Instagram}
                alt="Instagram"
              />
            </a>
          </div>
        </div>
        <a
          className={styles.hideMobile}
          href="https://liraglobal.com"
          target="_blank"
          rel="noreferrer">
          <img className={styles.logo} src={Logo} alt="Logo" />
        </a>
        <div className={styles.menu}>
          <a
            className={[styles.menuItem, styles.hideMobile].join(' ')}
            href="https://liraglobal.com"
            target="_blank"
            rel="noreferrer">
            LiRA Global
          </a>
          <a
            className={styles.menuItem}
            href="https://liraglobal.com/ourstory/"
            target="_blank"
            rel="noreferrer">
            About Us
          </a>
          <a
            className={styles.menuItem}
            href="https://liraglobal.com/contact-us/"
            target="_blank"
            rel="noreferrer">
            Contact
          </a>
          <Link className={styles.menuItem} to="/terms">
            Terms of use
          </Link>
        </div>
      </div>
      <div className={styles.footerCopyRight}>
        <span className={styles.copyRight}>
          © 2023 LiRA. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
