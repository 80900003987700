import React, { useState } from 'react';
import Webcam from 'react-webcam';
import styles from './Camera.module.css';

const Camera = ({ isRecording, webcamRef, className }) => {
  const [isCameraReady, setIsCameraReady] = useState(false);

  const onUserMediaHandler = () => {
    setIsCameraReady(true);
  };

  const videoConstraints = {
    facingMode: 'user'
  };

  return (
    <div className={className}>
      <div className={styles.cameraWrapper}>
        <Webcam
          audio
          mirrored
          ref={webcamRef}
          className={styles.camera}
          videoConstraints={videoConstraints}
          onUserMedia={onUserMediaHandler}
        />
        {isRecording && <div id="indicator" className={styles.indicator} />}

        {!isCameraReady && (
          <div className={styles.alert}>Camera is turning on...</div>
        )}
      </div>
    </div>
  );
};

export default Camera;
