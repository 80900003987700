import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import Camera from '../../components/Camera/Camera';
import AppFooter from '../../components/AppFooter';
import FaceOverlayBg from '../../assets/Images/face-overlay.png';
import FaceOverlayBgMobile from '../../assets/Images/face-overlay-mobile.png';
import LogoRed from '../../assets/Images/logo-vertical.png';
import LogoGreyHalf from '../../assets/Images/lira-logo-grey-horizontal-half.png';
import LogoGreyFull from '../../assets/Images/lira-logo-grey-horizontal.png';
import BtnBgYello from '../../assets/Images/btn-bg-yellow.svg';
import BtnBgRed from '../../assets/Images/btn-bg-red.svg';
import ResumeIcon from '../../assets/Images/resume.svg';
import PauseIcon from '../../assets/Images/pause.svg';
import RepeatIcon from '../../assets/Images/repeat.svg';
import { getNextSentence, addVideo } from '../../services/LiraLipTrain';
import {
  postVideo,
  startRecording,
  stopRecording,
  pauseRecording,
  resumeRecording
} from '../../components/Camera/CameraHelper';
import styles from './Record.module.css';

const STEPS = {
  READY: 'READY',
  START_RECORDING: 'START_RECORDING',
  RECORDING: 'RECORDING',
  NEXT_SENTENCE: 'NEXT_SENTENCE'
};

const MILESTONES = [1, 10, 25, 50, 80];

const Record = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.READY);
  const [currentSentenceIdx, setCurrentSentenceIdx] = useState(1);
  const [sentence, setSentence] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [isFetchingNext, setIsFetchingNext] = useState(false);

  const webcamRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    getNextSentence()
      .then(setSentence)
      .catch((error) => {
        toast.error('Error while getting new sentence');
        throw error;
      });
  }, []);

  useEffect(() => {
    if (currentSentenceIdx === MILESTONES[0] + 1) {
      toast('Amazing! Tap the button again to show the next sentence');
    } else if (currentSentenceIdx === MILESTONES[1] + 1) {
      toast('Nice start! 10 sentences down');
    } else if (currentSentenceIdx === MILESTONES[2] + 1) {
      toast('This is amazing! Keep it up');
    } else if (currentSentenceIdx === MILESTONES[3] + 1) {
      toast('You are halfway to become a HERO for the voiceless');
    } else if (currentSentenceIdx === MILESTONES[4] + 1) {
      toast('Almost there! 20 sentences to go!');
    }
  }, [currentSentenceIdx]);

  const onPost = async () => {
    setIsPosting(true);
    const fileName = await postVideo();

    fileName &&
      addVideo(sentence.SentenceId, fileName).then(async () => {
        const nextSentence = await getNextSentence();
        setSentence(nextSentence);
      });
    setIsPosting(false);
  };

  const onStart = () => {
    setCurrentStep(STEPS.START_RECORDING);
  };

  const onRecord = () => {
    setCurrentStep(STEPS.RECORDING);
    startRecording(webcamRef.current.stream);
    setIsPaused(false);
  };

  const onStopRecording = () => {
    setCurrentStep(STEPS.NEXT_SENTENCE);
    stopRecording();
    setIsPaused(false);
  };

  const onNextSentence = async () => {
    setIsFetchingNext(true);
    await onPost();
    const nextSentence = await getNextSentence();
    setSentence(nextSentence);
    setIsFetchingNext(false);
    setCurrentSentenceIdx(currentSentenceIdx + 1);
    setCurrentStep(STEPS.START_RECORDING);
  };

  const onRepeat = async () => {
    setCurrentStep(STEPS.START_RECORDING);
  };

  const onPause = () => {
    setIsPaused(true);
    pauseRecording();
  };

  const onResume = () => {
    setIsPaused(false);
    resumeRecording();
  };

  const onSubmit = async () => {
    await onPost();
    history.push({
      pathname: '/thank-you',
      state: { sentences: currentSentenceIdx }
    });
  };

  const renderStartBtn = () => {
    if (currentStep === STEPS.READY) {
      return (
        <button className={styles.btnStart} onClick={onStart}>
          <img src={BtnBgYello} alt="Button" className={styles.btnStartBg} />
          <span className={styles.btnStartText}>Start</span>
        </button>
      );
    }

    if (currentStep === STEPS.START_RECORDING) {
      return (
        <button
          className={styles.btnStart}
          disabled={isPosting}
          onClick={onRecord}>
          <img src={BtnBgRed} alt="Button" className={styles.btnStartBg} />
          <span className={styles.btnStartText}>Start Recording</span>
        </button>
      );
    }

    if (currentStep === STEPS.RECORDING) {
      return (
        <button className={styles.btnStart} onClick={onStopRecording}>
          <img src={BtnBgRed} alt="Button" className={styles.btnStartBg} />
          <span className={styles.btnStartText}>Stop Recording</span>
        </button>
      );
    }

    if (currentStep === STEPS.NEXT_SENTENCE) {
      return (
        <button className={styles.btnStart} onClick={onNextSentence}>
          <img src={BtnBgYello} alt="Button" className={styles.btnStartBg} />
          <span className={styles.btnStartText}>
            {isFetchingNext ? <BeatLoader color="#FFF" /> : 'Next Sentence'}
          </span>
        </button>
      );
    }
  };

  return (
    <div className={styles.container}>
      <img
        className={[styles.logoRedMobile, styles.showMobile].join(' ')}
        src={LogoRed}
        alt="Logo"
      />
      <div className={styles.pageLayout}>
        <div className={styles.cameraArea}>
          <div className={styles.instructionContainer}>
            <img
              className={[
                styles.faceOverlay,
                styles.hideMobile,
                currentStep !== STEPS.READY && styles.transparent
              ].join(' ')}
              src={FaceOverlayBg}
              alt="Face"
            />
            <img
              className={[
                styles.faceOverlayMobile,
                styles.showMobile,
                currentStep !== STEPS.READY && styles.transparent
              ].join(' ')}
              src={FaceOverlayBgMobile}
              alt="Face"
            />
            <Camera
              isRecording={false}
              disabled={false}
              webcamRef={webcamRef}
              className={styles.camera}
            />
            {currentStep !== STEPS.READY && (
              <div className={styles.statusBar}>
                {MILESTONES.map((current) => (
                  <div
                    className={[
                      styles.status,
                      current < currentSentenceIdx && styles.statusDone
                    ].join(' ')}
                    key={current}
                  />
                ))}
              </div>
            )}
            <div className={styles.sentenceAndAction}>
              {currentStep !== STEPS.READY && (
                <div
                  className={[
                    styles.sentenceContainer,
                    currentStep === STEPS.RECORDING && styles.sentenceRecording
                  ].join(' ')}>
                  <span className={styles.sentence}>
                    {sentence ? sentence.SentenceText : ''}
                  </span>
                </div>
              )}
              <div className={styles.actionContainer}>
                {currentStep !== STEPS.READY && (
                  <button
                    className={styles.btnCircle}
                    disabled={currentStep !== STEPS.RECORDING}
                    onClick={isPaused ? onResume : onPause}>
                    <img
                      src={
                        isPaused || currentStep !== STEPS.RECORDING
                          ? ResumeIcon
                          : PauseIcon
                      }
                      alt="Pause"
                      className={styles.btnCircleIcon}
                    />
                  </button>
                )}
                {renderStartBtn()}
                {currentStep !== STEPS.READY && (
                  <button
                    className={styles.btnCircle}
                    disabled={
                      currentStep !== STEPS.RECORDING ||
                      currentStep !== STEPS.NEXT_SENTENCE
                    }
                    onClick={onRepeat}>
                    <img
                      src={RepeatIcon}
                      alt="Repeat"
                      className={styles.btnCircleIcon}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.termsArea}>
          <img
            className={[styles.logoRed, styles.hideMobile].join(' ')}
            src={LogoRed}
            alt="Logo"
          />
          {(currentStep !== STEPS.NEXT_SENTENCE || currentSentenceIdx < 30) && (
            <div className={styles.agreeInstructionContainer}>
              <p className={styles.agreeInstruction}>
                {currentStep === STEPS.READY && (
                  <>
                    <span className={styles.fontRed}>Click Start</span> when you
                    are ready, and face is centered.
                  </>
                )}
                {currentStep === STEPS.START_RECORDING &&
                  currentSentenceIdx === 1 && (
                    <>
                      <span className={styles.fontRed}>Start recording</span>{' '}
                      and read aloud the sentence on the screen.
                    </>
                  )}
                {currentStep === STEPS.START_RECORDING &&
                  currentSentenceIdx > 1 && (
                    <>
                      Click Start Recording and Read aloud the{' '}
                      <span className={styles.fontRed}>next sentence</span> on
                      the screen.
                      <br />
                      <br />
                      You can pause or repeat as needed.
                    </>
                  )}
                {currentStep === STEPS.RECORDING && (
                  <>Read aloud the sentence on the screen.</>
                )}
                {currentStep === STEPS.NEXT_SENTENCE &&
                  currentSentenceIdx < 30 && (
                    <>
                      You can pause or repeat as needed.
                      <br />
                      <br />
                      When you click{' '}
                      <span className={styles.fontRed}>Next Sentence</span>, the
                      current sentence is uploaded.
                    </>
                  )}
              </p>
              <img
                className={[styles.logoGrey, styles.hideMobile].join(' ')}
                src={LogoGreyHalf}
                alt="Logo"
              />
            </div>
          )}
          {currentStep === STEPS.NEXT_SENTENCE && currentSentenceIdx >= 30 && (
            <div className={styles.submitContainer}>
              <p className={styles.agreeInstruction}>
                Thank you, we would love for you to continue to read sentences.
                <br />
                <br />
                You can end participation by clicking{' '}
                <span className={styles.fontRed}>Submit</span> when you finish.
              </p>
              <button className={styles.btnStart} onClick={onSubmit}>
                <img
                  src={BtnBgYello}
                  alt="Button"
                  className={styles.btnStartBg}
                />
                <span className={styles.btnStartText}>Submit</span>
              </button>
            </div>
          )}
          {currentStep !== STEPS.READY && currentSentenceIdx > 1 && (
            <div className={styles.wordsCompletedCard}>
              <div className={styles.wordsCompleted}>
                <span
                  className={[styles.completedText, styles.hideMobile].join(
                    ' '
                  )}>
                  Sentences Completed
                </span>
                <span
                  className={[styles.completedText, styles.showMobile].join(
                    ' '
                  )}>
                  Sentences
                </span>
                <span className={styles.completedCount}>
                  {currentSentenceIdx - 1}
                </span>
              </div>
              <img
                className={[styles.logoGrey, styles.hideMobile].join(' ')}
                src={LogoGreyFull}
                alt="Logo"
              />
            </div>
          )}
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default Record;
