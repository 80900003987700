import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/Images/logo-vertical.png';
import styles from './Terms.module.css';

const Terms = () => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.header}>
        <a href="https://liraglobal.com/">
          <img className={styles.logo} src={Logo} alt="Logo" />
        </a>
      </div>
      <div className={styles.terms}>
        <div className={styles.container}>
          <h2 className={styles.title}>Consent to participate</h2>
          <p className={styles.text}>
            This is your opportunity to take part in the data collection program
            known as LipTrain by LiRA Global. The purpose of LipTrain is to
            develop a lip-reading tool to restore communication for individuals
            who are unable to produce voice.
          </p>
          <p className={styles.text}>
            You must be at least 18 years old to participate. If you are younger
            than 18 years old, please stop now.
          </p>
          <p className={styles.text}>
            Participating in a data collection program is completely voluntary.
            You can also stop participating at any time.
          </p>
          <p className={styles.text}>
            If you agree to take part in this program, you will be asked to
            record a {`"selfie"`} style video of yourself reading provided
            sentences (with a healthcare focus). Videos are then electronically
            uploaded to our library automatically. Your participation will take
            about 3-5 minutes.
          </p>
          <p className={styles.text}>
            To protect your identity, do not include any personal identifying
            information (name, birthday, address, etc.) in the recording. Data
            will be stored on an encrypted, password protected. This
            deidentified data is computationally analyzed to train our
            lip-reading algorithm.
          </p>
          <p className={styles.text}>
            By submitting any material to LiRA for use in connection with this
            LipTrain video collection, you transfer ownership of all submitted
            materials and information, including documents, data, and content,
            in any form or media, directly or indirectly provided or made
            available to LiRA by your video upload.
          </p>
          <p className={styles.text}>
            Further, you agree to assign to the LiRA team the entire right,
            title, and interest in any idea, invention, design, or other work of
            authorship that results from, uses, or is otherwise connected to the
            information you provide in connection with this video collection.
          </p>
          <p className={styles.text}>
            If you have any questions about this data collection program, please
            contact LiRA team by emailing info@liraglobal.com.
          </p>
          <button className={styles.backBtn} onClick={onBack}>
            Back to LipTrain
          </button>
          <p className={styles.copyright}>
            Copyright © 2023 · All Rights Reserved · LiRA Global
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
