import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import Home from './pages/Home/Home';
import Record from './pages/Record';
import Prepare from './pages/Prepare';
import ThankYou from './pages/ThankYou';
import Terms from './pages/Terms';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <div className="App">
      <Router basename={process.env.REACT_APP_BASE_NAME}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/record">
            <Record />
          </Route>
          <Route exact path="/prepare">
            <Prepare />
          </Route>
          <Route exact path="/thank-you">
            <ThankYou />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default App;
