import { v4 as uuidv4 } from 'uuid';

export const getNextSentence = async () => {
  const personId = localStorage.getItem('personId');

  if (!personId) {
    return;
  }

  return await sendRequest(`person/nextsentence/${personId}`);
};

export const getOrCreateUser = async (email) => {
  if (email.trim() === '') {
    email = localStorage.getItem('email');
  }

  if (email) {
    let person = await getPersonByEmail(email);
    if (person) {
      localStorage.setItem('personId', person.PersonId);
      localStorage.setItem('email', person.Email);
      return;
    }
    const personId = localStorage.getItem('personId');

    if (personId) {
      person = await getPersonById(personId);

      if (person) {
        await sendRequest(`person/${personId}`, 'PATCH', { email });
        localStorage.setItem('email', email);
        return;
      }
    }
  }

  email = email || `${uuidv4()}@liraglobal.com`;
  localStorage.setItem('email', email);

  await sendRequest('person', 'PUT', { email });
  await getPersonByEmail(email);
};

const getPersonByEmail = async (email) => {
  const url = `email?email=${email}`;

  const person = await sendRequest(url);

  if (person[0] && person[0].PersonId) {
    localStorage.setItem('personId', person[0].PersonId);
    return person[0];
  }
};
const getPersonById = async (personId) => {
  const url = `person/${personId}`;

  const person = await sendRequest(url);

  return person[0];
};

export const addVideo = async (sentenceId, videoName) => {
  const data = {
    PersonId: localStorage.getItem('personId'),
    SentenceId: sentenceId,
    StoragePath: videoName
  };

  return sendRequest('video', 'PUT', data);
};

const sendRequest = async (url, method = 'GET', data = null) => {
  const baseURL = process.env.REACT_APP_API_URL;

  const response = await fetch(baseURL + url, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: data && JSON.stringify(data)
  });
  return response.json();
};
