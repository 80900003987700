import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AppFooter.module.css';

const AppFooter = () => (
  <div className={styles.footer}>
    <span className={styles.footerText}>© 2023 LiRA. All rights reserved.</span>
    <div className={styles.menu}>
      <Link
        className={[styles.footerText, styles.menuItem].join(' ')}
        to="/terms">
        Terms of use
      </Link>
    </div>
  </div>
);

export default AppFooter;
